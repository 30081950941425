<template>
  <section class="floor">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>La maison à étage : avantages et inconvénients</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/floor-1.jpg"
            alt="La maison à étage : avantages et inconvénients"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>La meilleure solution pour exploiter un terrain de petite taille</h2>
              <p>C’est décidé ? Vous voulez faire construire ? Vous allez accéder à votre rêve, celui de posséder votre maison individuelle neuve ? Félicitations… Mais pour quel type de maison allez-vous opter ? Maison de plain-pied ou maison à étage ?</p>
              <p>Si vous choisissez une maison à étage, sachez que vous allez bénéficier de nombreux avantages dont le principal est de trouver sa place sur des terrains de petites tailles ou exigus, problématique courante au cœur des communes et dans les zones urbaines.</p>
              <p>D’aucuns la trouve plus esthétique ou plus stylée en jouant sur les volumes mais, au-delà de cette aspect subjectif, elle gagne en hauteur, donc en vue et en ensoleillement, ce qu’elle perd en vis-à-vis et en nuisances sonores.</p>
              <p>On peut évidemment lui reprocher l’escalier, source potentielle d’insécurité, mais elle présente les inconvénients de ses avantages, essentiellement celui de séparer les espaces de vie pour une vivabilité accrue.</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/floor-2.jpg"
              alt="La meilleure solution pour exploiter un terrain de petite taille"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>La maison qui maîtrise les espaces de vie et optimise votre budget</h2>
              <p>La maison à étage offre un attribut de confort très appréciable, celui de scinder la maison en 2 : un espace de jour en rez-de-chaussée et un espace de nuit à l’étage. Ces 2 parties permettent de consacrer chaque niveau à des usages distincts : de repos, d’intimité ou dédiés à la vie familiale et sociale. Du coup, l’escalier indispensable à ce type de plans de maison constitue un élément déterminant, tant par sa vocation de circulation que par sa dimension esthétique. Si l’escalier est une source d’inquiétude, vous pouvez opter pour une suite parentale au rez-de-chaussée !</p>
              <p>Polyvalente sur les terrains les plus petits, elle permet de construire une maison plus spacieuse : en conséquence, la maison à étage est une solution moins coûteuse car elle permet de gagner en surface habitable tout en limitant certains surcoûts : fondations, terrassement, toiture… Faites vos calculs avec votre constructeur Maisons Alysia.</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/floor-3.jpg"
              alt="La maison qui maîtrise les espaces de vie et optimise votre budget"
            />
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'floor',
};
</script>

<style lang="sass">
.floor
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 30rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
        .smartpage-card-img
          margin-bottom: -25rem
      .smartpage-costing
        @include smartpage-costing
      .smartpage-card-img
        margin-top: -30%
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
        .smartpage-card-img
          margin-top: 1.5rem
        .smartpage-card-reverse
          .smartpage-card-img
            margin-bottom: 0
      .card + .card
        margin-top: 1.5rem
</style>
